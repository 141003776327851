<template>
    <div class="card flex-fill w-100 mt-2"
            style="background-image: url('/img/bg-tet.jpg'); background-size: cover; background-position: bottom;">
        <!--<div class="text-center">
            <div>
                <img src="/img/avatars/duyhh@irender.vn.png"
                        style="width: 20rem;"/>
            </div>
            <div>
                <h4 class="mt-3"><strong class="text-info">There is no information to display</strong></h4>
                <div><i class="text-muted">This item is under construction</i></div>
            </div>
        </div>-->
        <div  v-loading="isLoadingData" >
            <div class="d-flex align-items-center">
                <div class="p-2 pl-4 pr-3 pb-0 flex-fill">
                    <h4 class="m-0"><strong class="text-navy">Collected some infomations</strong></h4>
                </div>
                <div class="p-2 pr-4">
                    <el-button type="primary" class="bg-gradient-primary m-0" round size="medium"
                               style="border: 3px solid #fff;" 
                               @click="getTotalRecharge">
                        <i class="el-icon-refresh-left"></i> Refresh
                    </el-button>
                </div>
            </div>
            <div class="card-footer mt-0 pt-0 pb-0" v-if="rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box">
                            <span class="info-box-icon bg-info elevation-1"><i class="fas fa-users"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Number of users</span>
                                <span class="info-box-number">
                                    {{totalRechage.totalUserCount | numFormat('0,0[.][00]')}}
                                    <small>users</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-coins"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Number of users who paid</span>
                                <span class="info-box-number">
                                    {{totalRechage.totalPaidUserCount | numFormat('0,0[.][00]')}}
                                    <small>users</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->
                    <!-- fix for small devices only -->
                    <div class="clearfix hidden-md-up"></div>

                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="far fa-dot-circle"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Seeding source match</span>
                                <span class="info-box-number">
                                    {{totalRechage.seedingSourceMatch | numFormat('0,0[.][00]')}}
                                    <small>users</small>
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-money-bill-wave"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text">Total recharged</span>
                                <span class="info-box-number">
                                    ${{totalRechage.totalRecharged | numFormat('0,0[.][00]')}}
                                </span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                        <!-- /.info-box -->
                    </div>
                    <!-- /.col -->
                </div>
                <div class="row pt-0">
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline">
                                    <strong class="text-warning">+ ${{totalRechage.totalAmount | numFormat('0,0[.][00]')}}</strong> /
                                    <strong>+ {{totalRechage.totalPoint | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong>
                                </h6>
                            </div>
                            <span class="description-text">TOTAL COLLECTED</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline">
                                    <strong class="text-warning">+ ${{totalRechage.today | numFormat('0,0[.][00]')}}</strong> /
                                    <strong>+ {{totalRechage.todayPoint | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong>
                                </h6>
                            </div>
                            <span class="description-text">TODAY COLLECTED</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline">
                                    <strong class="text-warning">+ ${{totalRechage.thisMonth | numFormat('0,0[.][00]')}}</strong> /
                                    <strong>+ {{totalRechage.thisMonthPoint | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong>
                                </h6>
                            </div>
                            <span class="description-text">THIS MONTH COLLECTED</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline">
                                    <strong class="text-warning">+ ${{totalRechage.lastMonth | numFormat('0,0[.][00]')}}</strong> /
                                    <strong>+ {{totalRechage.lastMonthPoint | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong>
                                </h6>
                            </div>
                            <span class="description-text">LAST MONTH COLLECTED</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline text-white">
                                    <strong class="text-warning">
                                        + ${{totalRechage.avgAmountThisMonth | numFormat('0,0[.][00]')}} / user
                                    </strong>
                                </h6>
                            </div>
                            <span class="description-text">AVERAGE MONTH RECHARGED</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                </div>
                <!-- /.row -->
                <div class="row pt-0 mt-0">
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy mt-0" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline text-white"><strong>- {{totalRechage.totalPointSpent | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong></h6>
                            </div>
                            <span class="description-text">TOTAL SPENT</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy mt-0" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline text-white"><strong>- {{totalRechage.todaySpent | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong></h6>
                            </div>
                            <span class="description-text">TODAY SPENT</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy mt-0" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline text-white"><strong>- {{totalRechage.thisMonthSpent | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong></h6>
                            </div>
                            <span class="description-text">THIS MONTH SPENT</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy mt-0" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline text-white"><strong>- {{totalRechage.lastMonthSpent | numFormat('0,0[.][00]')}} {{$pointNameShort}}</strong></h6>
                            </div>
                            <span class="description-text">LAST MONTH SPENT</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <div class="col-12 col-sm-6 col-md-20p col-lg-20p pb-0 pt-0">
                        <div class="description-block info-box bg-gradient-navy mt-0" style="min-height: unset;">
                            <div>
                                <h6 class="description-header d-inline"></h6>
                                <h6 class="d-inline text-white"><strong>- {{totalRechage.avgSpentThisMonth | numFormat('0,0[.][00]')}} {{$pointNameShort}} / user</strong></h6>
                            </div>
                            <span class="description-text">AVERAGE MONTH SPENT</span>
                        </div>
                        <!-- /.description-block -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import gpuHubTransactionApi from '@/api/gpuHub/gpuHubTransaction';
    export default {
        props: {
            staffId: {
                type: String,
                default: null,
            },
        },
        watch: {
            staffId(newVal) {
                this.getTotalRecharge();
            },
        },
        data() {
            return {
                isLoadingData : false,
                totalRechage: {
                    lastMonth: 0,
                    lastMonthPoint: 0,
                    thisMonth: 0,
                    thisMonthPoint: 0,
                    today: 0,
                    todayPoint: 0,
                    totalAmount: 0,
                    totalPoint: 0,
                },
            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.auth.user,
                rolesInfo: state => state.auth.roles,
            }),
        },
        mounted() {
            this.getTotalRecharge();
        },
        methods: {
            getTotalRecharge() {
                this.isLoadingData = true;
                gpuHubTransactionApi.getTotalRechargeByStaff(this.staffId).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.$set(this, "totalRechage", response.data.data);
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                    this.isLoadingData = false;
                }).catch(error => {
                    console.error(error);
                    this.isLoadingData = false;
                });
            },
        }
    }
</script>